import React from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import Pagination from '@/components/pagination';
import { graphql } from 'gatsby';
import ProductTemplate, { Product } from '@/components/product';


interface QueryProps {
  allFile: {
    edges: {
      node: { childMarkdownRemark: Product };
    }[];
  };
}

export default function products({ data, pageContext }: { data: QueryProps, pageContext: any }) {
  const products = data.allFile.edges.map(({ node: { childMarkdownRemark: product } }) =>
    <ProductTemplate key={product.frontmatter.id} product={product} />);

  return (
    <Layout>
      <SEO title="Shop" />

      <div className="mx-auto" style={{ maxWidth: '1280px' }}>
        <h1
          className="pt-8 text-4xl font-bold md:text-5xl text-center"
          title="Shop">
          Shop
        </h1>

        <section className="w-full px-4 py-12 mx-auto md:grid">
          {products}
        </section>

        <Pagination {...pageContext} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allFile(
      filter: {sourceInstanceName: {eq: "products"}}
      sort: {fields: childrenMarkdownRemark___frontmatter___price, order: DESC}
    ) {
      edges {
        node {
          childMarkdownRemark {
            id
            frontmatter {
              description
              id
              price
              title
              customField {
                name
                values {
                  name
                  priceChange
                }
              }
              images {
                src {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
