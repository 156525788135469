import React, { useState } from 'react';
import Img from 'gatsby-image';

export interface Product {
  frontmatter: {
    title: string;
    images: { src: any }[];
    price: number;
    description: string;
    id: string;
    customField: CustomField;
  };
}

interface CustomField {
  name: string,
  values: { name: string, priceChange: number }[];
}


export default function Product({ product }: { product: Product }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState('MD');

  return (
    <div
      className={``}
      key={product.frontmatter.id}>
      <div
        className='border-2 border-charcoal flex flex-wrap'
        style={{ background: '#f5f6f8', }}>
        <div className='w-full md:w-1/4'>
          <Img
            alt={product.frontmatter.title}
            fluid={product.frontmatter.images[selectedImageIndex].src.childImageSharp.fluid} />

          <div className="flex border-t-2 border-charcoal bg-white w-full">
            { product.frontmatter.images.map((image, index) => (
              <div
                className="flex-1"
                onClick={() => setSelectedImageIndex(index)}>
                <Img
                  alt={product.frontmatter.title}
                  fluid={product.frontmatter.images[index].src.childImageSharp.fluid}
                  className={`${selectedImageIndex !== index ? 'opacity-50' : ''} cursor-pointer hover:opacity-100 transition-all`} />
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white border-t-2 md:border-t-0 md:border-l-2 border-charcoal flex flex-col justify-between md:w-3/4 w-full">
          <div className="px-8">
            <h2 className="text-xl flex justify-between py-4">
              <span>{product.frontmatter.title}</span>

              <span>${product.frontmatter.price}</span>
            </h2>

            <div className="py-2">
              { product.frontmatter.description }
            </div>

            <div className="py-2">
              <h3>{product.frontmatter.customField.name}</h3>

              <div className="py-2 flex flex-wrap">
                { product.frontmatter.customField.values.map(val => (
                  <span
                    className={`text-xs cursor-pointer border-2 border-charcoal px-4 py-1 rounded-full font-title mr-2 mb-2 hover:bg-charcoal hover:text-offwhite transition-all ${val.name === selectedSize ? 'text-offwhite bg-charcoal' : 'text-charcoal'}`}
                    onClick={() => setSelectedSize(val.name)}>
                    {val.name}
                  </span>
                )) }
              </div>
            </div>
          </div>

          <button
            className="snipcart-add-item w-full py-4 px-8 bg-charcoal hover:opacity-95 text-wheat font-title"
            data-item-id={product.frontmatter.id}
            data-item-price={product.frontmatter.price}
            data-item-url="/products"
            data-item-image={product.frontmatter.images[0].src.childImageSharp.fluid.src}
            data-item-name={product.frontmatter.title}
            data-item-custom1-name={product.frontmatter.customField ? product.frontmatter.customField.name : null}
            data-item-custom1-options={createSnipcartOptions(product.frontmatter.customField.values)}
            data-item-custom1-value={selectedSize}>
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}
 // create the string required by snipcart to allow price changes based on option chosen
function createSnipcartOptions(values: CustomField['values']) {
  return values.map(option => {
    const price = option.priceChange >= 0 ? `[+${option.priceChange}]` : `[${option.priceChange}]`
    return `${option.name}${price}`
  }).join('|')
}
